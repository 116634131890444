<template>
  <div class="container mx-auto">
    <div class="flex flex-col items-center justify-center mt-10">
      <img
        alt="86-404"
        :src="require(`@/assets/images/86-404.svg`)"
        width="220"
        class="hidden sm:block"
      />
      <img
        alt="86-404-mobile"
        :src="require(`@/assets/images/86-404-mobile.svg`)"
        width="240"
        class="mt-6 sm:hidden"
      />
      <div class="mt-20">
        <div class="text-lg font-semibold">Halaman Tidak Ditemukan</div>
        <div class="mt-2 text-sm">
          <div>Halaman yang anda cari tidak ada atau ada kesalahan teknis.</div>
          <router-link :to="`/`" class="font-medium underline">
            Kembali ke Halaman Utama
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
