<template>
  <div class="py-2 bg-grayscale-900">
    <span class="text-grayscale-300">Anda telah login pada: </span>
    <span class="text-gray-50"> {{ dateTime(token?.user?.lastLogin) }}</span>
  </div>
</template>

<script>
// import { ref } from 'vue';
import moment from "moment";

export default {
  name: "Greeting",
  setup() {
    const token = JSON.parse(window.localStorage.getItem("86"));
    const dateTime = (value) => {
      return moment(value, "DD/MM/YYYY")
        .lang("id")
        .format("dddd, DD MMMM YYYY");
    };

    return { dateTime, token };
  },
};
</script>