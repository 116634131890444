<template>
  <div class="container px-5 mx-auto mt-12">
    <div
      class="flex flex-col items-center justify-between p-4 border sm:flex-row rounded-xl sm:p-6"
    >
      <div class="flex justify-between flex-1 items-cemter">
        <img
          alt="86-complete-profile"
          :src="require(`@/assets/images/86-complete-profile.svg`)"
          class="me-4"
          width="56"
        />
        <div class="flex-1 text-left">
          <div
            class="text-base leading-6 text-gray-900 sm:text-2xl sm:leading-8"
          >
            Halo, <span class="font-semibold"> {{ token?.user?.name }}!</span>
          </div>
          <div class="text-xs sm:text-base">
            Anda berhasil masuk ke akun anda.
            <span v-if="auth?.user?.isProfileComplete">
              Saatnya eksplor acara-acara menarik!
            </span>
            <span v-else> Saatnya melengkapi profil anda! </span>
          </div>
        </div>
      </div>
      <router-link v-if="!auth?.user?.isProfileComplete" :to="'/profile'">
        <button-general
          :title="'Lengkapi Profil'"
          :size="'s'"
          :color="'outline'"
          class="w-full mt-4 text-sm sm:w-auto sm:mt-0"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import ButtonGeneral from "../buttons/buttonGeneral.vue";
import store from "@/store";
export default {
  name: "CompleteProfile",
  components: {
    ButtonGeneral,
  },
  setup() {
    const token = JSON.parse(window.localStorage.getItem("86"));

    onMounted(() => {
      store.dispatch("getUpdateUser");
    });

    const auth = computed(() => store.getters.getDetailAuth);

    return { token, auth };
  },
};
</script>
