<template>
  <div class="font-inter">
    <alert :show="showAlert" />
    <div class="fixed top-5 right-5 z-[9999999] flex flex-col flex-col-reverse">
      <alert2
        v-for="(alert, i) in alerts"
        :key="i"
        :id="alert.id"
        :status="alert.status"
        :title="alert.title"
        :message="alert.message"
        class="mt-4"
      />
    </div>
    <router-view :key="$route.path" />
  </div>
</template>

<script>
import store from "./store";
import Alert from "@/components/alert.vue";
import Alert2 from "@/components/alert2.vue";

export default {
  components: { Alert, Alert2 },
  name: "App",
  computed: {
    showAlert() {
      return store.getters.getShowAlert;
    },
    alerts() {
      return store.getters.getAlerts;
    },
    showConfirmation() {
      return store.getters.getShowConfirmation;
    },
    showMenu() {
      return store.getters.getShowMenu;
    },
  },
  mounted() {
    store.dispatch("getUpdateUser");
  },
  watch: {
    showConfirmation() {
      if (this.showConfirmation) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    showMenu() {
      if (this.showMenu) {
        document.body.classList.add("overflow-hidden");
        document.body.classList.add("sm:overflow-auto");
        document.body.classList.remove("overflow-auto");
      } else {
        document.body.classList.add("overflow-auto");
        document.body.classList.remove("overflow-hidden");
        document.body.classList.remove("sm:overflow-auto");
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
