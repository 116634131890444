<template>
  <div class="relative">
    <swiper
      :loop="true"
      :pagination="true"
      :modules="modules"
      class="mySwiper"
      :autoplay="{
        delay: 2500,
      }"
      style="height: calc(100vh - 90px)"
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="(item, index) in listBanner"
        :key="index"
        class="relative"
      >
        <div>
          <img
            alt="86-image-sample-header"
            :src="item.bannerUrl"
            class="absolute object-cover w-full h-full"
          />
          <div
            class="h-full w-full absolute object-cover from-grayscale-900 to-grayscale-900 opacity-60 bg-gradient-to-b sm:bg-gradient-to-r sm:opacity-100 sm:from-20% sm:to-transparent"
          />
        </div>
        <div
          v-if="item.bannerType === 'BANNER_HOME'"
          class="container relative flex flex-col justify-between w-full h-full px-5 py-12 mx-auto"
        >
          <img
            alt="86-logo-banner"
            :src="require(`@/assets/images/86-logo-banner.svg`)"
            class="w-[170px] sm:w-[348px]"
          />
          <div class="flex flex-col items-start text-justify sm:flex-row">
            <div class="bg-primary-50 rounded-xl w-full sm:w-[336px] mr-8">
              <div class="p-4 text-base font-semibold sm:text-lg">
                Our Vision
              </div>
              <div class="p-4 text-sm bg-white sm:text-base rounded-xl">
                For Delapan Enam University to become a leading, internationally
                recognized institution in innovative and socially-focused
                further learning
              </div>
            </div>
            <div
              class="bg-primary-50 rounded-xl w-full sm:w-[440px] mt-4 sm:mt-0"
            >
              <div class="p-4 text-base font-semibold sm:text-lg">
                Our Mission
              </div>
              <div class="p-4 text-sm bg-white sm:text-base rounded-xl">
                For Delapan Enam University to lead movements in
                socially-centered learning, commit to exceptional knowledge
                sharing, empowering society, facilitating creative innovation
                and professional collaboration
              </div>
            </div>
          </div>
        </div>
        <div v-else class="container h-full px-5 mx-auto">
          <div
            class="relative flex flex-col h-full w-auto sm:w-[756px] justify-center py-12 text-left text-white"
          >
            <div class="font-playfair font-bold text-[40px] sm:text-[64px]">
              {{ item.name }}
            </div>
            <div class="mt-8 text-base sm:text-lg line-clamp-3 sm:line-clamp-2">
              {{ item.extendInfo.description }}
            </div>
            <div class="mt-8 text-base sm:text-xl">
              <div class="flex">
                <img
                  alt="86-banner-map"
                  :src="require(`@/assets/images/86-banner-map.svg`)"
                  width="24"
                  class="mr-3"
                />{{ item.extendInfo.location }}
              </div>
              <div class="flex mt-3">
                <img
                  alt="86-banner-clock"
                  :src="require(`@/assets/images/86-banner-clock.svg`)"
                  width="24"
                  class="mr-3"
                />
                {{ item.extendInfo.date }}
              </div>
            </div>
            <div class="mt-8">
              <router-link :to="`/event/${item.redirectUrl}`">
                <button-general
                  :title="'Lihat Acara'"
                  :color="'white'"
                  :width="'auto'"
                  class="text-sm sm:text-lg py-[10px] sm:py-4 px-4 sm:px-7"
                />
              </router-link>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div
      class="hidden z-10 absolute left-12 top-[50%] -translate-y-1/2 sm:flex items-center"
    >
      <button
        class="p-2 text-white rounded-full shadow-md sm:p-4 bg-white/25"
        @click="prev"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
          />
        </svg>
      </button>
    </div>
    <div
      class="hidden z-10 absolute top-[50%] -translate-y-1/2 sm:right-12 sm:flex items-center"
    >
      <button
        class="p-2 text-white rounded-full shadow-md sm:p-4 bg-white/25"
        @click="next"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { computed } from "vue";
import store from "@/store";
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    ButtonGeneral,
  },
  name: "Header",
  data() {
    return {
      swiper: null,
    };
  },
  setup() {
    const listBanner = computed(() => {
      return store.getters.getListBannerLanding;
    });

    return { listBanner, modules: [Autoplay, Navigation, Pagination] };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    next() {
      this.swiper.slideNext();
    },
    prev() {
      this.swiper.slidePrev();
    },
  },
};
</script>
