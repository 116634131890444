<template>
  <div class="w-100">
    <div class="container px-5 py-5 mx-auto">
      <div class="flex items-center justify-between">
        <div class="flex item-center">
          <div class="w-2 mr-4 rounded-full bg-secondary"></div>
          <div
            class="text-xl leading-10 sm:text-[40px] font-semibold sm:leading-[56px] text-grayscale-900"
          >
            Kategori Acara
          </div>
        </div>
        <button-general
          :title="'Lihat Semua'"
          :size="'s'"
          :color="'outline'"
          :width="'auto'"
          class="text-xs sm:text-base"
          @click="goToListEventPage"
        />
      </div>
      <div class="grid grid-cols-2 gap-3 mt-5 sm:grid-cols-3 sm:gap-6 sm:mt-6">
        <div v-for="(item, index) in listCategory" :key="index">
          <card-event-category-user
            :detail="item"
            @click="$emit('click', item.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import store from "@/store";
import ButtonGeneral from "../buttons/buttonGeneral.vue";
import CardEventCategoryUser from "@/components/events/cardEventCategoryUser.vue";
import { useRouter } from "vue-router";

export default {
  name: "EventCategoryUser",
  components: {
    ButtonGeneral,
    CardEventCategoryUser,
  },
  emits: ["click"],
  setup() {
    const listCategory = computed(() => store.getters.getListCategory);

    const router = useRouter();

    const goToListEventPage = () => {
      router.push("/event");
    };

    return { listCategory, goToListEventPage };
  },
};
</script>
