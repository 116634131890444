<template>
  <router-link :to="`/event/${detail.id}`" class="text-left">
    <img
      alt="86-sample-event"
      :src="detail.imageUrl"
      class="object-cover w-full h-[265px] sm:h-[300px] rounded-2xl"
    />
    <div class="mt-4 text-sm leading-6 sm:text-base text-grayscale-500">
      {{ dateTime(detail.startDate) }} &#x2022;
      {{ detail.eventCategory.displayName }}
    </div>
    <div
      class="text-lg sm:text-2xl font-semibold leading-8 text-[#121212] h-16 mt-3"
    >
      {{
        `${detail.name.slice(0, 35)}` +
        `${detail.name.length > 40 ? "..." : ""}`
      }}
      <!-- {{ detail.name.length }} -->
    </div>
    <div class="flex mt-5">
      <div class="flex pr-3 border-r">
        <img
          alt="86-user"
          :src="detail.user.picture"
          width="32"
          class="h-8 mr-2 rounded-full"
        />
        <div>
          <div class="text-xs leading-4">Oleh</div>
          <div class="text-sm font-semibold leading-5">
            {{ detail.user.name }}
          </div>
        </div>
      </div>
      <div class="flex flex-1 ml-3 sm:relative">
        <div
          v-if="detail.totalParticipant"
          class="hidden w-20 sm:flex sm:items-center"
        >
          <img
            v-for="(participant, index) in detail.participants.slice(1, 4)"
            :key="index"
            alt="86-sample-participant"
            :src="participant.picture"
            width="32"
            height="32"
            class="object-cover w-8 h-8 -mr-3 border border-white rounded-full"
          />
        </div>
        <div
          v-else
          class="items-center justify-center hidden w-8 h-8 mr-2 rounded-full sm:flex bg-grayscale-100"
        >
          <img
            alt="86-user-black"
            :src="require(`@/assets/images/86-user-black.svg`)"
            width="32"
            height="32"
            class="h-[18px] w-[18px]"
          />
        </div>
        <div>
          <div class="text-xs leading-4">Partisipan</div>
          <div class="text-sm font-semibold leading-5">
            {{ detail.totalParticipant }} pendaftar
          </div>
        </div>
      </div>
    </div>
    <button-general
      :title="'Lihat Acara'"
      :size="'s'"
      :color="'outline'"
      class="mt-6 text-sm"
    />
  </router-link>
</template>

<script>
import ButtonGeneral from "@/components/buttons/buttonGeneral.vue";
import moment from "moment";

export default {
  name: "CardEventUser",
  components: {
    ButtonGeneral,
  },
  props: {
    detail: {
      type: Array,
    },
  },
  setup() {
    const dateTime = (value) => {
      return moment(value, "DD/MM/YYYY")
        .lang("id")
        .format("dddd, DD MMMM YYYY");
    };

    return { dateTime };
  },
};
</script>
