<template>
  <div class="w-100">
    <div class="container px-5 mx-auto">
      <div class="flex items-center justify-between">
        <div class="flex item-center">
          <div class="w-2 mr-4 rounded-full bg-secondary"></div>
          <div
            class="text-xl leading-10 sm:text-[40px] font-semibold sm:leading-[56px] text-grayscale-900"
          >
            {{ title }}
          </div>
        </div>
        <router-link :to="'/event'">
          <button-general
            :title="'Lihat Semua'"
            :size="'s'"
            :color="'outline'"
            :width="'auto'"
            class="text-xs sm:text-base"
          />
        </router-link>
      </div>
      <template v-if="listEvent?.length">
        <div
          class="grid grid-cols-1 gap-6 mt-5 sm:grid-cols-3 sm:gap-8 sm:mt-8"
        >
          <div v-for="(item, index) in listEvent" :key="index">
            <card-event-user :detail="item" />
          </div>
        </div>
      </template>
      <div v-else class="w-full mt-8">
        <div class="mx-auto text-center">
          <img
            :src="require(`@/assets/images/86-empty-comment.svg`)"
            alt="Belum Ada Komentar"
            class="w-[256px] inline-block"
          />
          <p class="text-lg font-semibold text-grayscale-900">
            Acara Tidak Ada
          </p>
          <p class="text-sm text-grayscale-500">
            Kami tidak bisa menemukan Acara yang Anda cari.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import store from "@/store";
import ButtonGeneral from "../buttons/buttonGeneral.vue";
import CardEventUser from "@/components/events/cardEventUser.vue";

export default {
  name: "EventUser",
  components: {
    ButtonGeneral,
    CardEventUser,
  },
  props: {
    title: {
      type: String,
      default: "Berita Acara",
    },
  },
  setup() {
    const listEvent = computed(() => store.getters.getListEvent);

    return { listEvent };
  },
};
</script>
