<template>
  <div class="sticky top-0 z-[100] w-full bg-primary-50">
    <div class="container flex items-center justify-between px-5 py-5 mx-auto">
      <router-link :to="'/'">
        <img
          alt="86-logo-black"
          :src="require(`@/assets/images/86-logo-black.svg`)"
        />
      </router-link>
      <div class="items-center hidden sm:flex">
        <router-link
          :to="'/'"
          class="mr-12 text-base leading-6"
          :class="route.path === '/' ? 'font-semibold' : 'font-normal'"
        >
          Beranda
        </router-link>
        <router-link
          :to="'/about-us'"
          class="mr-12 text-base leading-6"
          :class="route.path === '/about-us' ? 'font-semibold' : 'font-normal'"
        >
          Tentang Kami
        </router-link>
        <router-link
          :to="'/event'"
          class="text-base leading-6"
          :class="route.path === '/event' ? 'font-semibold' : 'font-normal'"
        >
          List Acara
        </router-link>
      </div>
      <mini-profile-user class="hidden sm:flex" v-if="token !== null" />
      <div v-else class="items-center hidden sm:flex">
        <router-link
          :to="'/login'"
          class="text-base leading-6 text-grayscale-900 mr-7"
        >
          Masuk Akun
        </router-link>
        <router-link :to="'/register'">
          <button-general
            :title="'Daftar Anggota'"
            :size="'s'"
            :color="'outline'"
            :width="'auto'"
            class="text-grayscale-900 border-grayscale-900"
          />
        </router-link>
      </div>
      <img
        alt="86-menu-mobile-user"
        :src="require(`@/assets/images/86-menu-mobile-user.svg`)"
        class="block sm:hidden"
        @click="setShowMenu"
      />
    </div>
    <div
      class="p-5 text-left sm:hidden text-grayscale-900"
      :class="showMenu ? 'block' : 'hidden'"
      style="height: calc(100vh - 72px)"
    >
      <div class="text-lg font-semibold">Main Menu</div>
      <div class="flex flex-col items-start">
        <router-link
          :to="'/'"
          class="w-full py-4 text-base leading-6 border-b border-b-grayscale-900"
        >
          Beranda
        </router-link>
        <router-link
          :to="'/about-us'"
          class="w-full py-4 text-base leading-6 border-b border-b-grayscale-900"
        >
          Tentang Kami
        </router-link>
        <router-link :to="'/event'" class="w-full py-4 text-base leading-6">
          List Acara
        </router-link>
      </div>
      <div v-if="token === null">
        <div class="mt-16 text-lg font-semibold">Gabung 86 University</div>
        <div class="flex flex-col items-start">
          <router-link
            :to="'/login'"
            class="w-full py-4 text-base leading-6 border-b border-b-grayscale-900"
          >
            Masuk Akun
          </router-link>
          <router-link
            :to="'/register'"
            class="w-full py-4 text-base leading-6"
          >
            Daftar Akun
          </router-link>
        </div>
      </div>
      <div v-else>
        <div class="mt-12 text-lg font-semibold">Profil</div>
        <div class="flex flex-col items-start">
          <router-link
            :to="'/notifications'"
            class="w-full py-4 text-base leading-6 border-b border-b-grayscale-900"
          >
            Notifikasi
            <span
              v-if="totalUnreadNotifications"
              class="px-2 py-1 ml-2 text-xs text-white rounded-full bg-secondary"
            >
              {{ totalUnreadNotifications }}</span
            >
          </router-link>
          <router-link
            :to="'/profile'"
            class="flex items-center w-full py-4 text-base font-medium leading-6 border-b border-b-grayscale-900"
          >
            <img
              :src="detailAuth?.user?.picture"
              alt="Avatar"
              class="w-10 h-10 mr-3 rounded-full"
            />
            {{ token?.user?.name }}
          </router-link>
          <button
            class="w-full py-4 text-base leading-6 text-left"
            @click="handleShowConfirmation"
          >
            Keluar Akun
          </button>
        </div>
      </div>
    </div>
    <confirmation
      :show="showConfirmation"
      :title="'Keluar Akun'"
      :message="'Apakah anda yakin ingin keluar dari akun anda? Anda perlu masuk kembali untuk mengakses akun anda.'"
      @close="handleShowConfirmation"
      @submit="confirmLogout"
    />
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import ButtonGeneral from "../buttons/buttonGeneral.vue";
import MiniProfileUser from "@/components/profiles/miniProfileUser.vue";
import Confirmation from "@/components/confirmation.vue";
import axios from "axios";

export default {
  name: "TopbarUsers",
  components: {
    ButtonGeneral,
    MiniProfileUser,
    Confirmation,
  },
  setup() {
    const detailAuth = computed(() => store.getters.getDetailAuth);
    const token = JSON.parse(window.localStorage.getItem("86"));
    const route = useRoute();
    const showMenu = computed(() => {
      return store.getters.getShowMenu;
    });

    const setShowMenu = () => {
      store.commit("setShowMenu", !store.getters.getShowMenu);
    };

    const confirmLogout = () => {
      localStorage.removeItem("86");
      window.location.reload();
    };

    const showConfirmation = computed(() => {
      return store.getters.getShowConfirmation;
    });

    const handleShowConfirmation = () => {
      store.commit("setShowConfirmation", !store.getters.getShowConfirmation);
    };

    onMounted(() => {
      getNotifications();
    });

    onUnmounted(() => {
      store.commit("setShowMenu", false);
      store.commit("setShowConfirmation", false);
    });

    const totalUnreadNotifications = ref(0);
    const getNotifications = async () => {
      const params = {
        offset: 1,
        limit: 1,
      };
      const url =
        process.env.VUE_APP_API_URL +
        "/api/v2/notification?" +
        new URLSearchParams(params).toString();
      try {
        const response = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `
						Bearer ${JSON.parse(window.localStorage.getItem("86"))?.token}`,
          },
        });
        totalUnreadNotifications.value = response.data.result.totalUnread;
      } catch (error) {
        console.error(error);
      }
    };

    return {
      detailAuth,
      token,
      route,
      showMenu,
      setShowMenu,
      confirmLogout,
      showConfirmation,
      handleShowConfirmation,
      totalUnreadNotifications,
    };
  },
};
</script>
